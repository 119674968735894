{
  "name": "@vladmandic/face-api",
  "version": "1.7.13",
  "description": "FaceAPI: AI-powered Face Detection & Rotation Tracking, Face Description & Recognition, Age & Gender & Emotion Prediction for Browser and NodeJS using TensorFlow/JS",
  "sideEffects": false,
  "main": "dist/face-api.node.js",
  "module": "dist/face-api.esm.js",
  "browser": "dist/face-api.esm.js",
  "types": "types/face-api.d.ts",
  "author": "Vladimir Mandic <mandic00@live.com>",
  "bugs": {
    "url": "https://github.com/vladmandic/face-api/issues"
  },
  "homepage": "https://vladmandic.github.io/face-api/demo/webcam.html",
  "license": "MIT",
  "engines": {
    "node": ">=14.0.0"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/vladmandic/face-api.git"
  },
  "scripts": {
    "start": "node --no-warnings demo/node.js",
    "build": "node build.js",
    "dev": "build --profile development",
    "typings": "build --profile typings",
    "lint": "eslint src/ demo/",
    "test": "node --trace-warnings test/test-node.js",
    "scan": "npx auditjs@latest ossi --dev --quiet"
  },
  "keywords": [
    "face-api",
    "faceapi",
    "face-detection",
    "age-gender",
    "emotion-detection",
    "face-recognition",
    "face",
    "face-description",
    "tensorflow",
    "tensorflowjs",
    "tfjs"
  ],
  "devDependencies": {
    "@canvas/image": "^2.0.0",
    "@microsoft/api-extractor": "^7.39.1",
    "@tensorflow/tfjs": "^4.16.0",
    "@tensorflow/tfjs-backend-cpu": "^4.16.0",
    "@tensorflow/tfjs-backend-wasm": "^4.16.0",
    "@tensorflow/tfjs-backend-webgl": "^4.16.0",
    "@tensorflow/tfjs-backend-webgpu": "4.16.0",
    "@tensorflow/tfjs-converter": "^4.16.0",
    "@tensorflow/tfjs-core": "^4.16.0",
    "@tensorflow/tfjs-data": "^4.16.0",
    "@tensorflow/tfjs-layers": "^4.16.0",
    "@tensorflow/tfjs-node": "^4.16.0",
    "@tensorflow/tfjs-node-gpu": "^4.16.0",
    "@types/node": "^20.11.5",
    "@types/offscreencanvas": "^2019.7.3",
    "@typescript-eslint/eslint-plugin": "^6.19.0",
    "@typescript-eslint/parser": "^6.19.0",
    "@vladmandic/build": "^0.9.3",
    "@vladmandic/pilogger": "^0.4.9",
    "esbuild": "^0.19.11",
    "eslint": "^8.56.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-json": "^3.1.0",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-promise": "^6.1.1",
    "node-fetch": "^3.3.2",
    "rimraf": "^5.0.5",
    "seedrandom": "^3.0.5",
    "tslib": "^2.6.2",
    "typedoc": "^0.25.7",
    "typescript": "5.3.3"
  }
}
